const selectItem = {
  monthItem: [
    { value: null, text: "--" },
    { value: 1, text: "01" },
    { value: 2, text: "02" },
    { value: 3, text: "03" },
    { value: 4, text: "04" },
    { value: 5, text: "05" },
    { value: 6, text: "06" },
    { value: 7, text: "07" },
    { value: 8, text: "08" },
    { value: 9, text: "09" },
    { value: 10, text: "10" },
    { value: 11, text: "11" },
    { value: 12, text: "12" },
  ],
  dayItem: [
    { value: null, text: "--" },
    { value: 1, text: "01" },
    { value: 2, text: "02" },
    { value: 3, text: "03" },
    { value: 4, text: "04" },
    { value: 5, text: "05" },
    { value: 6, text: "06" },
    { value: 7, text: "07" },
    { value: 8, text: "08" },
    { value: 9, text: "09" },
    { value: 10, text: "10" },
    { value: 11, text: "11" },
    { value: 12, text: "12" },
    { value: 13, text: "13" },
    { value: 14, text: "14" },
    { value: 15, text: "15" },
    { value: 16, text: "16" },
    { value: 17, text: "17" },
    { value: 18, text: "18" },
    { value: 19, text: "19" },
    { value: 20, text: "20" },
    { value: 21, text: "21" },
    { value: 22, text: "22" },
    { value: 23, text: "23" },
    { value: 24, text: "24" },
    { value: 25, text: "25" },
    { value: 26, text: "26" },
    { value: 27, text: "27" },
    { value: 28, text: "28" },
    { value: 29, text: "29" },
    { value: 30, text: "30" },
    { value: 31, text: "31" },
  ],
  weekItem: [
    { value: null, text: "--" },
    { value: 0, text: "日" },
    { value: 1, text: "月" },
    { value: 2, text: "火" },
    { value: 3, text: "水" },
    { value: 4, text: "木" },
    { value: 5, text: "金" },
    { value: 6, text: "土" },
  ],
  hourItem: [
    { value: null, text: "--" },
    { value: 0, text: "00" },
    { value: 1, text: "01" },
    { value: 2, text: "02" },
    { value: 3, text: "03" },
    { value: 4, text: "04" },
    { value: 5, text: "05" },
    { value: 6, text: "06" },
    { value: 7, text: "07" },
    { value: 8, text: "08" },
    { value: 9, text: "09" },
    { value: 10, text: "10" },
    { value: 11, text: "11" },
    { value: 12, text: "12" },
    { value: 13, text: "13" },
    { value: 14, text: "14" },
    { value: 15, text: "15" },
    { value: 16, text: "16" },
    { value: 17, text: "17" },
    { value: 18, text: "18" },
    { value: 19, text: "19" },
    { value: 20, text: "20" },
    { value: 21, text: "21" },
    { value: 22, text: "22" },
    { value: 23, text: "23" },
  ],
  minuteItem: [
    { value: null, text: "--" },
    { value: 0, text: "00" },
    { value: 1, text: "01" },
    { value: 2, text: "02" },
    { value: 3, text: "03" },
    { value: 4, text: "04" },
    { value: 5, text: "05" },
    { value: 6, text: "06" },
    { value: 7, text: "07" },
    { value: 8, text: "08" },
    { value: 9, text: "09" },
    { value: 10, text: "10" },
    { value: 11, text: "11" },
    { value: 12, text: "12" },
    { value: 13, text: "13" },
    { value: 14, text: "14" },
    { value: 15, text: "15" },
    { value: 16, text: "16" },
    { value: 17, text: "17" },
    { value: 18, text: "18" },
    { value: 19, text: "19" },
    { value: 20, text: "20" },
    { value: 21, text: "21" },
    { value: 22, text: "22" },
    { value: 23, text: "23" },
    { value: 24, text: "24" },
    { value: 25, text: "25" },
    { value: 26, text: "26" },
    { value: 27, text: "27" },
    { value: 28, text: "28" },
    { value: 29, text: "29" },
    { value: 30, text: "30" },
    { value: 31, text: "31" },
    { value: 32, text: "32" },
    { value: 33, text: "33" },
    { value: 34, text: "34" },
    { value: 35, text: "35" },
    { value: 36, text: "36" },
    { value: 37, text: "37" },
    { value: 38, text: "38" },
    { value: 39, text: "39" },
    { value: 40, text: "40" },
    { value: 41, text: "41" },
    { value: 42, text: "42" },
    { value: 43, text: "43" },
    { value: 44, text: "44" },
    { value: 45, text: "45" },
    { value: 46, text: "46" },
    { value: 47, text: "47" },
    { value: 48, text: "48" },
    { value: 49, text: "49" },
    { value: 50, text: "50" },
    { value: 51, text: "51" },
    { value: 52, text: "52" },
    { value: 53, text: "53" },
    { value: 54, text: "54" },
    { value: 55, text: "55" },
    { value: 56, text: "56" },
    { value: 57, text: "57" },
    { value: 58, text: "58" },
    { value: 59, text: "59" },
  ],
  cupItem: [
    { value: null, text: "--" },
    { value: "A", text: "A" },
    { value: "B", text: "B" },
    { value: "C", text: "C" },
    { value: "D", text: "D" },
    { value: "E", text: "E" },
    { value: "F", text: "F" },
    { value: "G", text: "G" },
    { value: "H", text: "H" },
    { value: "I", text: "I" },
    { value: "J", text: "J" },
    { value: "K", text: "K" },
    { value: "L", text: "L" },
    { value: "M", text: "M" },
    { value: "N", text: "N" },
    { value: "O", text: "O" },
    { value: "P", text: "P" },
    { value: "Q", text: "Q" },
    { value: "R", text: "R" },
    { value: "S", text: "S" },
    { value: "T", text: "T" },
    { value: "U", text: "U" },
    { value: "V", text: "V" },
    { value: "W", text: "W" },
    { value: "X", text: "X" },
    { value: "Y", text: "Y" },
    { value: "Z", text: "Z" },
  ],
  bloodTypeItem: [
    { value: null, text: "--" },
    { value: "A", text: "A" },
    { value: "B", text: "B" },
    { value: "O", text: "O" },
    { value: "AB", text: "AB" },
  ],
  signItem: [
    { value: null, text: "--" },
    { value: "1", text: "牡羊座" },
    { value: "2", text: "牡牛座" },
    { value: "3", text: "双子座" },
    { value: "4", text: "蟹座" },
    { value: "5", text: "獅子座" },
    { value: "6", text: "乙女座" },
    { value: "7", text: "天秤座" },
    { value: "8", text: "蠍座" },
    { value: "9", text: "射手座" },
    { value: "10", text: "山羊座" },
    { value: "11", text: "水瓶座" },
    { value: "12", text: "魚座" },
  ],
  styleItem: [
    { value: null, text: "--" },
    { value: "2", text: "スレンダー" },
    { value: "3", text: "スタンダード" },
    { value: "4", text: "グラマー" },
    { value: "5", text: "ややぽっちゃり" },
    { value: "6", text: "ぽっちゃり" },
  ],
  typeItem: [
    { value: null, text: "--" },
    { value: "1", text: "カワイイ系" },
    { value: "2", text: "キレイ系" },
    { value: "3", text: "ロリ系" },
    { value: "4", text: "人妻系" },
  ],
  informationTypeItem: [
    { value: 1, text: "お知らせ" },
    { value: 2, text: "お知らせ2" },
  ],
  feeTypeItem: [
    { value: null, text: "未設定" },
    { value: 1, text: "初期費用" },
    { value: 2, text: "月額利用料" },
  ],
  castupTypeItem: [
    { value: 1, text: "チェックしたセラピストを対象とする" },
    {
      value: 2,
      text: "チェックしたセラピストで、当日に出勤予定があるセラピストを対象とする",
    },
  ],
};

export default selectItem;
