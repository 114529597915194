<template>
  <div>
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5 mb-2">
      トップに表示するピックアップセラピストを選択してください。
    </div>
    <v-container fluid>
      <v-row>
        <v-col cols="6"></v-col>
        <v-col cols="6">
          <div>
            <v-text-field
              v-model="castSearch"
              append-icon="mdi-magnify"
              label="（セラピスト名検索）"
              single-line
              hide-details
              clearable
              dense
              class="mb-2"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      id="cast_table"
      :headers="castHeaders"
      :items="casts"
      :search="castSearch"
      item-key="id"
      show-select
      :single-select="false"
      v-model="castSelected"
      :mobile-breakpoint="0"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
        'items-per-page-text': '表示件数',
      }"
      @click:row="rowClick"
    >
      <!-- eslint-disable-next-line -->
            <template v-slot:footer.page-text="props">
        {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
          props.itemsLength
        }}件
      </template>
      <!-- 画像 -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.image="{ item }">
        <v-avatar class="ma-1"
          ><v-img :src="setting.cdn + item.image"
        /></v-avatar>
      </template>
      <!-- 名前 -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
      <!-- 公開 -->
      <!-- eslint-disable-next-line -->
            <template v-slot:item.enable="{ item }">
        <div class="pa-1">
          <v-chip
            v-if="item.is_enable"
            class="ma-2"
            color="primary"
            label
            small
          >
            公開中
          </v-chip>
          <v-chip v-else class="ma-2" label small> 非公開 </v-chip>
        </div>
      </template>
      <template v-slot:item.profile="{ item }">
        <span class="profile_span"
          >{{ item.profile_1 }}<br />{{ item.profile_2 }}</span
        >
      </template>
      <template v-slot:no-data> セラピストが登録されていません </template>
    </v-data-table>
    <v-container>
      <v-row justify="end" class="mt-3">
        <v-col cols="6" sm="3">
          <general-button btn_type="info" btn_block @click-event="onSubmit"
            ><template v-slot:icon
              ><v-icon left>mdi-content-save</v-icon></template
            >保存</general-button
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  ref,
  set,
  reactive,
  toRefs,
  defineComponent,
  watch,
  onMounted,
} from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";

export default defineComponent({
  props: ["shop_site_id"],
  setup(props, ctx) {
    const ShopSitePickupsRepository = repositoryFactory.get("shopSitePickups");
    const CastsRepository = repositoryFactory.get("casts");

    const state = reactive({
      // キャスト
      casts: [],
      castSearch: "",
      castSelected: [],
      castHeaders: [
        {
          text: "画像",
          value: "image",
          sortable: false,
          align: "center",
          class: "td_image",
          width: 80,
        },
        {
          text: "セラピスト名",
          value: "name",
          sortable: true,
          class: "td_name",
        },
        { text: "公開/非公開", value: "enable", sortable: true, width: 110 },
        {
          text: "プロフィール",
          value: "profile",
          sortable: false,
          class: "td_profile",
        },
      ],
    });

    onMounted(async () => {
      // セラピストデータ取得
      const currentShop = store.getters["shops/currentShop"];
      // パラメータセット
      let params = new URLSearchParams();
      state.casts.splice(0);
      params.append("shop_id", currentShop.id);
      await CastsRepository.list_profile(params)
        .then((response) => {
          if (response.data) {
            for (let i = 0; i < response.data.length; i++) {
              const common_shop_site = [];
              for (let j = 0; j < response.data[i].prop.length; j++) {
                common_shop_site.push(response.data[i].prop[j]);
              }
              const p_age = common_shop_site.find(
                (r) => r.key == "cast__basic__age"
              );
              const p_tall = common_shop_site.find(
                (r) => r.key == "cast__basic__tall"
              );
              const text_age =
                "年齢:" + (!p_age ? "__" : p_age["value"] ?? "__");
              const text_tall =
                " 身長:" + (!p_tall ? "__" : p_tall["value"] ?? "__");
              const profile_1 = text_age + text_tall;
              const p_bust = common_shop_site.find(
                (r) => r.key == "cast__basic__bust"
              );
              const p_cup = common_shop_site.find(
                (r) => r.key == "cast__basic__cup"
              );
              const p_waist = common_shop_site.find(
                (r) => r.key == "cast__basic__waist"
              );
              const p_hip = common_shop_site.find(
                (r) => r.key == "cast__basic__hip"
              );
              const text_bust =
                "B:" + (!p_bust ? "__" : p_bust["value"] ?? "__");
              const text_cup =
                "(" +
                (!p_cup || p_cup == null ? "__" : p_cup["value"] ?? "__") +
                ")";
              const text_waist =
                " W:" + (!p_waist ? "__" : p_waist["value"] ?? "__");
              const text_hip = " H:" + (!p_hip ? "__" : p_hip["value"] ?? "__");
              const profile_2 = text_bust + text_cup + text_waist + text_hip;
              const tmpCast = response.data[i];
              tmpCast.profile_1 = profile_1;
              tmpCast.profile_2 = profile_2;
              state.casts.push(tmpCast);
            }
          }
        })
        .catch((error) => {
          throw "ERROR:CastsRepository.list (" + error + ")";
        });

      const pickupCasts = await ShopSitePickupsRepository.list_shopsite(
        props.shop_site_id
      )
        .then((response) => {
          if (response.data) {
            return response.data.map((item) =>
              state.casts.find((cast) => cast.id == item.cast_id)
            );
          } else {
            return [];
          }
        })
        .catch((error) => {
          throw "ERROR:ShopSitePickupsRepository.get (" + error + ")";
        });

      state.castSelected = pickupCasts;
    });

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    const onSubmit = async () => {
      // ピックアップキャスト登録
      if (!window.confirm("保存してよろしいですか？")) {
        return false;
      }

      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      const pickupList = state.castSelected.map((item) => item.id);
      const params = {
        shop_site_id: props.shop_site_id,
        pickup_list: pickupList,
      };
      await ShopSitePickupsRepository.save_list(params)
        .then((response) => {
          if (response.data) {
            // 保存OK
          }
        })
        .catch((error) => {
          throw "ERROR:ShopSitePickupsRepository.save_list (" + error + ")";
        });
      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    };

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      rowClick,
      onSubmit,
    };
  },
});
</script>

<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .mini label {
  font-size: 0.75em;
}

::v-deep .td_name {
  min-width: 100px;
  width: 200px;
}
::v-deep .td_profile {
  min-width: 120px;
}
.profile_span {
  font-size: 0.75em;
}
::v-deep .td_shop_site {
  min-width: 200px;
}
.shop_site_span {
  font-size: 0.75em;
  background-color: #fafafa;
  word-break: break-all;
}
</style>
